<template>
  <b-container class="main-login">
    <!-- <b-row align-h="center">
      <b-col cols="8">
        <b-card text-variant="white" class="text-center">
          <img
            src="https://www.alianzadiagnostica.com/images/logo-img.png"
            alt=""
            class="mb-3"
          />
          <h4 style="background-color: #0A2799">
            Bienvenido al Servicio Virtual de Asignación de Citas
          </h4>
        </b-card>
      </b-col>
    </b-row> -->
    <b-row align-h="center" class="text-center">
      <b-col cols="12" sm="12" md="12" lg="8" xl="8">
        <b-card header-bg-variant="Info" class="text-left">
          <b-card-text class="text-center">
            <b-row>
              <b-col>
                <b-img 
                  src="https://saludtotal.com.co/wp-content/themes/salud-total/assets/img/logo-salud-total.svg" 
                  alt="Salud Total"
                  height="50%"
                ></b-img>
                <b-icon class="ml-3 mr-3" icon="dash" scale="4" rotate="-75" style="color:#0A2799"></b-icon>
                <b-img
                  src="https://www.alianzadiagnostica.com/images/logo-img.png"
                  alt=""
                  class="mb-3"
                  height="50%"
                ></b-img>
              </b-col>
            </b-row>            
            <h5 style="color: #0A2799">
              Listado de Citas para el afiliado:
            </h5>
            <br>
            <div class="text-left">
              <b>Nombre: </b>{{ $store.state.grupoFamiliar[0]?.Nombres == '' ? this.$store.state.nombreAfiliadoAdx : $store.state.grupoFamiliar[0]?.Nombres }} <br>
              <b>Documento: </b>{{ identificacionAfiliado }}
            </div>
          </b-card-text>
          <listar-citas :key="keyRender" :fechaCitaCancelar="$route.params.fecha"></listar-citas>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import ListarCitas from "../components/ListarCitasComponent.vue";

  export default {
    components: {
      "listar-citas": ListarCitas,
    },
    data() {
      return {
        // form: {
        //   userType: "AFI",
        //   identificationType: "C",
        //   identification: "1085095941",
        //   password: "saludtotal",
        // },
        keyRender: 1,
        nombreAfiliado: null,
        identificacionAfiliado: null,
        form: {
          userType: "AFI",
          identificacionId: null,
          identificacion: null,
          authCode: null
          // password: null,
        },
        msgNotificationUno: null,
        msgNotificationDos: null,
        showNotification: false,
        dismissSecs: 4,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        loading: false,
        authStep: 1,
        emailAfil: '',
        formatEmailAfil: '',
        changeEmail: false
      };
    },
    computed: {
      ...mapState([
        "tokenAuth",
        "identificationTypes",
        "userTypes",
        "tokenAfiliado",
      ]),
      // nombreAfil(){
      //   console.log('hjdfnhjfbnfghj',this.$store.state.grupoFamiliar)
      //   return  this.$store.state.grupoFamiliar && this.$store.state.grupoFamiliar[0] && this.$store.state.grupoFamiliar[0].Nombres ? this.$store.state.nombreAfiliadoAdx : this.$store.state.grupoFamiliar[0].Nombres
      //   return ''
      // }
    },
    mounted() {      
      this.$store.commit('setLoggedFromLinkMessage',true)
      this.fechaCitaCancelar = this.$route.params.fecha
      this.consultar()
    },
    beforeDestroy(){
      this.$store.commit('setLoggedFromLinkMessage',false)
    },
    methods: {
      ...mapActions(["loginApi", "logout", "getGrupoFamiliarAfil"]),
      cargarCitas(){
        let identificationTypeAfil = this.identificationTypes.find(
          (item) => item.valueAdx == this.$route.params.tipoDoc
        );
        let afilData = {
          identificacion: this.$route.params.identificacion, //this.form.identificacion.trim(),
          identificacionId: identificationTypeAfil.id
        };
        this.loginApi(afilData)
          .then(async (response) => {
            this.$store.dispatch('firstStepLogin',afilData)
              .then(async rta => {
                this.loading = false;
                if (rta.length == 0) {
                  this.msgNotificationUno = "El documento de identificación ingresado no se ha encontrado en la base de datos de protegidos de Salud Total. Por favor verifique la información.";
                  this.showNotification = true;
                  this.dismissCountDown = 8;
                  return
                }
                if(rta.invalidEmail){
                  this.changeEmail = true;
                  this.emailAfil =  ''
                  this.formatEmailAfil = ''
                }else{
                  this.emailAfil =  rta.email                  
                  this.formatEmailAfil = this.formatHideEmail(rta.email)                                    
                  this.$store.commit('setAfiliadoSaludTotal',rta.esSaludTotal);
                  if(!rta.esSaludTotal){
                    this.$store.commit('setNombreAfiliadoAdx',rta.nombreAfiliadoAdx);
                  }
                }
                let dataToGrupoFamiliar = {
                  userType: "AFI",
                  identificacionId: identificationTypeAfil.value,
                  identificacion: this.$route.params.identificacion
                }
                let rtaGrupoFamiliar = await this.getGrupoFamiliarAfil(dataToGrupoFamiliar)  
                this.keyRender++
                // this.nombreAfiliado = rtaGrupoFamiliar[0].Nombres
                this.identificacionAfiliado = rtaGrupoFamiliar[0].BeneficiarioId
              })
          })
          .catch((error) => {
            this.loading = false;
            this.msgNotificationUno = "Datos de acceso incorrectos.";
            this.showNotification = true;
            this.dismissCountDown = this.dismissSecs;
          });
      },
      consultar() {      
        this.loading = true;
        this.cargarCitas();
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs;
      },
      formatHideEmail(correo){
        let dominio = correo.substr(correo.indexOf('@')); 
        let cuenta = correo.substr(0,correo.indexOf('@'))
        let mostrar = cuenta.substr(0,cuenta.length-(cuenta.length/2))                
        return mostrar+'*****'+dominio;
      }
    },
  };
</script>
<style>
  .main-login {
    margin-top: 5vh;
  }
</style>
